import React from "react";
import {
  Box,
} from "@chakra-ui/react";
import Banner from "../Banner";
import Section from "./section";
import { useQuery } from "@apollo/client";
import { HOME_PAGE_TECH_CATEGORY } from "@/apollo/queries";
import Loader from "../Loader";

export default function Home(props) {
  const { data, loading, error } = useQuery(HOME_PAGE_TECH_CATEGORY);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    console.error(error);
    return null;
  }

  const { Head, SEO, Sections, Contact_Us } = data.homePage.data.attributes;
  const { data: Techs } = data.techCategories;

  return (
    <>
      {/* Head */}
      <Banner
        data={Head}
        style={{ align: "end", textAlign: "" }}
        classNameparent="overlay"
        homeBanner
      />

      {/* Sections */}
      {/* <Box bgGradient="linear-gradient(to-b, #ffffff, #00050e0f)"  pt={{ base: 14  , md: 16 }}> */}
      {Sections?.map((sec, index) => {
        return (
          <Box
            key={index}
            _odd={
              {
                bgGradient:"linear-gradient(to-b, #f3f4f6, #ffffff)",
                pb:0
              }
            }
            _even={{
              pb:10
            }}
            bgGradient="linear-gradient(to-b, #ffffff, #f3f4f6, #fffff)"
            px={{ base: 5,md: 8, xl:5}}
            py={{ base: 14  , md: 16 }}
          >
            {sec?.Name == "Technologies" ? (
              <Section data={sec} techs={Techs} />
            ) : (
              <Section data={sec} />
            )}
          </Box>
        );
      })}
      {/* </Box> */}

      {/* Contact Us */}
      <Banner
        data={Contact_Us}
        classNameparent="bottom-banner"
        classNamechild
      />
    </>
  );
}
